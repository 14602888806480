import React, { useEffect, useState } from "react"
import firebase from "../firebase"
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption } from "@chakra-ui/react"
import { Box, Text } from "@chakra-ui/layout"
import Navbar from "../components/Utilities/Navbar/Navbar"

const Admin = () => {
  const [userData, setUserData] = useState([])
  const [adminData, setAdminData] = useState([])

  useEffect(() => {
    firebase
      .firestore()
      .collection("leads")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setUserData(listItems)
      })
    return userData
  }, [userData])

  useEffect(() => {
    firebase
      .firestore()
      .collection("admin_email")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setAdminData(listItems)
      })
    return adminData
  }, [adminData])

  const isAdmin = adminData.find(
    el => el.email === sessionStorage.getItem("email")
  )

  console.log(isAdmin)

  return (
    <div>
      {isAdmin?.email ? (
        <>
          <Navbar />
          <Table variant="simple" maxW="940px" margin="0 auto" my="100px">
            <TableCaption>SenseTech Innovation Private Limited</TableCaption>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Service</Th>
                <Th isNumeric>Message</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userData.map(item => (
                <Tr>
                  <Td>{item.name}</Td>
                  <Td>{item.email}</Td>
                  <Td>{item.phone}</Td>
                  <Td>{item.service}</Td>
                  <Td isNumeric>{item.message}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Text textAlign="center" fontSize="30px">
            404 page not found
            <br /> or <br />
            You don't have permission to this url.
          </Text>
        </Box>
      )}
    </div>
  )
}

export default Admin
